
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  width: 100%;
  clear: both;
  position: relative;
}

.noClearfix {
  clear: none;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.gutter {
  padding-left: spacing(2);
  padding-right: spacing(2);

  @include mq($breakpoint-desktop) {
    padding-left: spacing(4);
    padding-right: spacing(4);
  }
}

// SIZES

.full {
  max-width: 100%;
}

.huge {
  max-width: spacing(156);

  @include mq($breakpoint-desktopLarge) {
    max-width: spacing(195);
  }
}

.wide {
  max-width: spacing(160);
}

.large {
  max-width: spacing(120);
}

.medium {
  max-width: spacing(100);
}

.small {
  max-width: spacing(60);
}


:global(.groupedWidgetsLayout) {
  & .Container {
    background-color: white;
    box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
    border-radius: 9px;
    padding-top: 16px;
    padding-bottom: 16px;
    &.dark {
      background-color: $color-primary-grey;
    }
  @include mq($breakpoint-tabletLandscape) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  @include mq($breakpoint-desktopMedium) {
    padding-top: 44px;
    padding-bottom: 44px;
    padding-left: spacing(4) + 44px;
    padding-right: spacing(4) + 44px;
  }
    &.huge {
     width: 95%;
    
    }
    &.full {
      padding: 0;
      box-shadow: none;
    }
  }
}
